<template>
  <div class="success">
    <el-result style="width: 100%" icon="success" title="同步">
      <div slot="subTitle" style="line-height: 1.5; color: #999">
        身份同步成功<br />稍后将返回至应用首页
      </div>
    </el-result>
  </div>
</template>

<script>
export default {};
</script>

<style>
.success {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>