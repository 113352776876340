<template>
  <div
    class="loadingbg"
    v-loading="true"
    element-loading-text="同步数据中..."
    element-loading-background="rgba(255, 255, 255, 0)"
  >
    <img src="../../public/lg.png" alt="" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    let at = this.$route.query.at;
    let vt = this.$route.query.vt;
    axios
      .post(
        "/api/rpc/2.0/brain/solution/faceprint/result/detail?access_token=" +
          at,
        {
          verify_token: vt,
        }
      )
      .then((res) => {
        if (res.data.success) {
          this.authRealName(
            res.data.result.idcard_confirm.idcard_number,
            res.data.result.idcard_confirm.name,
            JSON.stringify(res.data),
            this.$route.query.phone
          );
        } else {
          window.open("http://192.168.1.64:8080/error", "_self");
        }
      });
  },
  methods: {
    authRealName(idNumber, name, remark, phone) {
      axios
        .post("/veri/auth/VeriRecord", {
          idNumber,
          name,
          remark,
          phone,
        })
        .then((res) => {
          if (res.data.code == 200) {
            window.open("http://192.168.1.64:8080/success", "_self");
          } else {
            window.open("http://192.168.1.64:8080/error", "_self");
          }
        });
    },
  },
};
</script>

<style>
.loadingbg {
  background-image: url("../../public/bg.png");
  height: 100%;
  background-position-y: -150px;
  box-sizing: border-box;
  padding-top: 10vh;
}
.loadingbg img {
  display: block;
  width: 80vw;
  margin: 0 auto;
}
</style>