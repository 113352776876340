<template>
  <div class="homePage">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我签收的回执" name="first">
        <el-row :gutter="20" class="navSearch">
          <el-col :span="6">
            <el-date-picker v-model="date" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-button plain @click="getDataList">查询</el-button>
            <el-button type="primary" @click="downloadMorePDF" plain :disabled="checkedId.length <= 0">批量下载</el-button>
          </el-col>
          <el-col style="margin-top: -50px;"><el-button style="color: #fff; background-color: #d50700; border-color: #d50700; float: right;" plain @click="Tologin">退出登录</el-button></el-col>
        </el-row>
        <div class="container" v-loading="loading">
          <el-row :gutter="20">
            <h3></h3>
            <el-col v-for="item in data" :key="item.BillId" :span="8">
              <div class="containerBox">
                <el-checkbox class="checkBox" size="mini" v-model="item.checked"></el-checkbox>
                <h3 style="margin: 10px 0; text-align: center">
                  <span>电子回执单</span>
                  <span style="float: right; margin-right: 10px">
                    <el-button @click="toDownLoad(item.Id, item.BillId)" style="padding: 0; margin-right: 10px"
                      type="text">下载</el-button>
                    <router-link :to="{ path: '/Html', query: { prop: item.Id } }" target="_blank">
                      <el-button style="padding: 0" type="text">详情</el-button>
                    </router-link>
                  </span>
                </h3>
                <p class="liTitle" style="margin-bottom: 10px">
                  <span>单号:{{ item.BillId }}</span>
                  <span style="
                      float: right;
                      margin-right: 8px;
                      font-size: 12px;
                      color: #777;
                    ">{{ item.CreateTime.substring(0, 10) }}</span>
                </p>
                <p class="liTitle" style="margin-bottom: 10px">
                  发货方:{{ item.CompanyName }}
                  <span style="
                      float: right;
                      margin-right: 8px;
                      font-size: 12px;
                      margin-top: -6px;
                    ">
                    <el-tag v-if="item.Status == 0">待签收</el-tag>
                    <el-tag v-if="item.Status == 1" type="success">已签收</el-tag>
                    <el-tag v-if="item.Status == 2" type="danger">已拒收</el-tag>
                    <el-tag v-if="item.Status == 3" type="warning">差异签收</el-tag>
                    <el-tag v-if="item.Status == 4" type="danger">已作废</el-tag>
                  </span>
                </p>
                <div class="tableBox">
                  <table width="100%" border style="border-collapse: collapse; text-align: center">
                    <thead>
                      <th v-for="im in item.container.dataHeader" :key="im.title">
                        {{ im.title }}
                      </th>
                    </thead>
                    <tbody>
                      <tr v-for="(im, ix) in item.container.tableData" :key="ix">
                        <td v-for="val in item.container.dataHeader" :key="val.dataIndex">
                          {{ im[val.dataIndex] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-pagination v-show="pagination.total > 0" background layout="total,prev, pager, next,sizes"
            :total="pagination.total" :current-page="pagination.current" :page-size="pagination.pageSize"
            :page-sizes="pagination.pageSizeOptions" style="text-align: right; margin-top: 8px; padding: 0 0 10px 0"
            @current-change="currentChange" @size-change="handleSizeChange">
          </el-pagination>
          <el-empty description="暂无票据" :image-size="200" v-show="pagination.total == 0"></el-empty>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我的签章" name="third">
        <el-row :gutter="20">
          <div>
            <el-upload style="
                border: 1px dashed #d9d9d9;
                width: 200px;
                margin-left: 10px;
                border-radius: 6px;
              " class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imgUrl" :src="imgUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

const NumFormatting = function (data) {
  if (data != null) {
    return Number(data)
      .toFixed(2)
      .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
  } else {
    return "";
  }
};
export default {
  name: "Home",
  components: {},
  data() {
    return {
      activeName: "first",
      loading: false,
      keyword: "",
      date: null,
      userInfo: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      // 签章
      imgUrl: "", // 签章
    };
  },
  mounted() {
    this.getInitializeDate();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.userInfo) {
      this.$message.error("用户信息失效,请重新登陆");
      this.$router.replace("/");
      return;
    }
    this.getDataList();
  },
  computed: {
    checkedId() {
      let arr = this.data.filter((item) => item.checked).map((item) => item.Id);
      return arr;
    },
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 我签收的回执
    downloadMorePDF() {
      console.log(this.checkedId);
      this.$http
        .post("/BO/BilllistLog/GetPDFZip", this.checkedId)
        .then((res) => {
          if (res.Success) {
            downloadFile(this.$baseUrl + res.Data, "出库单");
          }
        });
    },
    toDownLoad(id, billId) {
      this.$http.post("/BO/BilllistLog/GetPDF?id=" + id).then((res) => {
        if (res.Success) {
          downloadFile(res.Data, "出库单" + billId);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    Tologin(){
      this.$router.replace('/')
    },

    NumFormatting(data) {
      return NumFormatting(data);
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/BilllistLog/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: {
            StartDate: this.date ? this.date[0] : "",
            EndDate: this.date ? this.date[1] : "",
            SupplieId: this.userInfo.Type == 1 ? this.userInfo.SupplieId : "",
            CustomerPhone: this.userInfo.Type == 1 ? "" : this.userInfo.Phone,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.data = res.Data;
            this.data.forEach((item) => {
              if (item.Content) {
                // item.checked = false;
                item.container = JSON.parse(item.Content);
                let idx = item.container.printlist.findIndex(
                  (item) => item.Keys == "table"
                );
                if (idx !== -1) {
                  item.container.tableData =
                    item.container.printlist[idx].datalist;
                  item.container.dataHeader =
                    item.container.printlist[idx].columns;
                } else {
                  item.container.tableData = [];
                }
              } else {
                item.container = {};
              }
            });
            this.pagination.total = res.Total;
          }
        });
    },
    getInitializeDate() {
      let date = new Date(); //获取新的时间
      //获取当前年份,并且转为字符串
      let year = date.getFullYear().toString();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : (date.getMonth() + 1).toString();
      let day =
        date.getDate() < 10
          ? "0" + date.getDate().toString()
          : date.getDate().toString();
      let start = year + "-" + month + "-01"; //当月第一天
      let end = year + "-" + month + "-" + day; //当天
      this.date = [start, end]; //将值设置给组件DatePicker 绑定的数据
    },

    // 我的签章

    handleAvatarSuccess(res, file) { },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        return this.$message.error("请选择JPG/PNG格式图片");
      }
      var _this = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // 图片base64化
        var newUrl = this.result; //图片路径
        _this.imgUrl = newUrl;
        _this.saveData();
      };
    },
    saveData() {
      console.log(this.imgUrl);
      this.$http
        .post("/Base_Manage/Base_Company/SaveSignature", {
          Signature: this.imgUrl,
        })
        .then((res) => {
          this.CompanyList[this.idx].loading = false;
          if (res.Success) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
};
</script>
<style scoped lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #fff;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.avatar {
  width: 200px;
  height: 200px;
  display: block;
}

/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 20%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}

/*去除a标签下划线*/
a {
  text-decoration: none;
  color: #000;
}

.homePage {
  height: 100%;
  width: 90vw;
  margin: 0 auto;
  padding-top: 20px;

  .heard {
    border-bottom: 1px solid #eee;

    .heard_right {
      text-align: right;
    }
  }

  .navSearch {
    padding: 10px 0;
  }

  .container {
    .containerBox {
      position: relative;
      // border: 1px solid #eee;
      overflow: hidden;
      margin-bottom: 12px;
      box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
      max-height: 400px;
      cursor: pointer;
      box-sizing: border-box;

      .tableBox {
        background: #efefef55;
        width: 100%;
        height: 290px;
        font-size: 12px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }

        &::-webkit-scrollbar-track {
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }

        .tableHeader {
          width: 100%;
          border: 1px solid #777;
          display: flex;
          box-sizing: border-box;
          text-align: center;
          border-right: none;

          span {
            border-right: 1px solid #777;
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
          }
        }

        .borderTopNone {
          border-top: none;
        }
      }

      .checkBox {
        position: absolute;
        zoom: 200%;
        top: 0;
        left: 4px;
      }

      // img {
      //   display: block;
      //   margin: 0 auto;
      //   // height: 325px;
      //   width: 100%;
      // }
      p {
        margin: 0;
        padding-left: 12px;
      }

      .liTitle {
        margin-top: 12px;
        font-size: 16px;

        .download {
          float: right;
          margin-right: 10px;
        }
      }

      .liContent {
        margin-top: 8px;
        font-size: 12px;
        color: #777;
      }
    }

    .containerBox:hover {
      animation-name: animation;
      animation-duration: 300ms;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes animation {
  0% {
    box-shadow: 0;
  }

  100% {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
  }
}
</style>