import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import http from './api/http'
import router from './router'
import Print from 'vue-print-nb'
import global_ from './config/global'
import VueDragResize from 'vue-drag-resize'
import '@/theme/theme/index.css'
Vue.prototype.$baseUrl = global_.BASE_URL
Vue.prototype.$http = http
Vue.config.productionTip = false
Vue.component('vue-drag-resize', VueDragResize)
Vue.use(ElementUI);
Vue.use(Print)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
