<template>
  <div class="login" v-loading="loading">
    <!-- <img src="../assets/loginBG.jpg" alt="" /> -->
    <div class="loginForm">
      <h1 style="color: #000000; ">HI~</h1>
      <h2 style=" color: #000000;font-family: 'Times New Roman', Georgia, Serif ">
        欢迎登录 "配送签"
      </h2>
      <div class="box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="签收方登录" name="first">
            <el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm" style="margin-top: 57px">
              <el-form-item label="手机号:" prop="user">
                <el-input v-model="ruleForm.user" placeholder="手机号" @keyup.enter.native="submit"></el-input>
              </el-form-item>
              <el-form-item label="密码:" prop="password">
                <el-input v-model="ruleForm.password" placeholder="密码" @keyup.enter.native="submit"
                  type="password"></el-input>
              </el-form-item>
              <div class="buttonBlock">
                <span class="errMsg" v-show="errMsg">{{ errMsg }}</span>
                <el-button class="formBtn"  round @click="submit">登 录</el-button>
              </div>
              <div class="Abtn"><a @click="ForgetPassword">忘记密码?</a></div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="发货方登录" name="second">
            <el-form label-width="80px" :model="ruleForm1" :rules="rules1" ref="ruleForm1" style="margin-top: 57px">
              <el-form-item label="手机号:" prop="user">
                <el-input v-model="ruleForm1.user" placeholder="手机号" @keyup.enter.native="CustomerSubmit"></el-input>
              </el-form-item>
              <el-form-item label="密码:" prop="password">
                <el-input v-model="ruleForm1.password" placeholder="密码" type="password"
                  @keyup.enter.native="CustomerSubmit"></el-input>
              </el-form-item>
              <div class="buttonBlock">
                <span class="errMsg" v-show="errMsg">{{ errMsg }}</span>
                <el-button class="formBtn" round @click="CustomerSubmit">登 录</el-button>
              </div>
              <div class="Abtn"><a @click="ForgetPassword">忘记密码?</a></div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 忘记密码框 -->
    <el-dialog title="重置密码" :visible.sync="ForgetVisible">
      <el-form ref="ForgetPasswordForm" :model="ForgetPasswordForm" label-width="80px" :rules="ForgetPasswordRules">
        <el-form-item label="手机号" prop="Phone">
          <el-input v-model="ForgetPasswordForm.Phone" placeholder="手机号" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            v-model="ForgetPasswordForm.oldPassword"
            placeholder="旧密码(如忘记可不输入)"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="新密码" prop="NewPwd">
          <el-input v-model="ForgetPasswordForm.NewPwd" placeholder="新密码" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="ConPwd">
          <el-input v-model="ForgetPasswordForm.ConPwd" placeholder="重复密码" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="SmsCode">
          <div class="formLabel">
            <el-input v-model="ForgetPasswordForm.SmsCode" placeholder="验证码" autocomplete="off"></el-input>

            <el-button type="primary" style="margin-left: 5px" :disabled="disabled" @click="sendCode">{{
              !disabled ? '发送验证码' : oneMinute + '重新发送'
            }}</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ForgetVisible = false">取 消</el-button>
        <el-button type="primary" @click="ForgetConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from '@/utils/base64' //引入
const regExp = new RegExp('^1[3456789]\\d{9}$') // 手机号正则
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ForgetPasswordForm.NewPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ForgetVisible: false,
      activeName: 'first',
      ruleForm: {},
      ruleForm1: {},
      ForgetPasswordForm: {},
      rules: {
        user: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入手机号'))
              } else if (!regExp.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      rules1: {
        user: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入手机号'))
              } else if (!regExp.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      ForgetPasswordRules: {
        // oldPassword: [
        //   { required: true, message: "请输入旧密码", trigger: "blur" },
        // ],
        Phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入手机号'))
              } else if (!regExp.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        NewPwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        ConPwd: [{ required: true, validator: validatePass, trigger: 'blur' }],
      },
      timer: null,
      oneMinute: 60,
      disabled: false,
      loading: false,
      base: new base(),
      errMsg: '',
    }
  },
  mounted() {
    if (this.$route.query.tap) {
      this.activeName = 'second'
    } else {
      this.activeName = 'first'
    }
  },
  methods: {
    // 忘记密码
    ForgetConfirm() {
      console.log(this.ForgetPasswordForm)
      this.$refs['ForgetPasswordForm'].validate((valid) => {
        if (!valid) {
          return
        }
        if (!this.ForgetPasswordForm.SmsCode) {
          return this.$message.error('请填写验证码')
        }
        // 客户
        if (this.activeName == 'first') {
          this.$http
            .post(
              `/BO/CustomerLinkman/SubResetLinkmanPwd?Phone=${this.ForgetPasswordForm.Phone}&ConPwd=${this.ForgetPasswordForm.ConPwd}&SmsCode=${this.ForgetPasswordForm.SmsCode}`
            )
            .then((res) => {
              if (res.Success) {
                this.$message.success('密码重置成功,请登录')
                this.ForgetVisible = false
              } else {
                this.$message.error(res.Msg)
              }
            })
        } else {
          // 用户
          this.$http
            .post(
              `/Base_Manage/Base_User/SubResetUserPwd?Phone=${this.ForgetPasswordForm.Phone}&ConPwd=${this.ForgetPasswordForm.ConPwd}&SmsCode=${this.ForgetPasswordForm.SmsCode}`
            )
            .then((res) => {
              if (res.Success) {
                this.$message.success('密码重置成功,请登录')
                this.ForgetVisible = false
              } else {
                this.$message.error(res.Msg)
              }
            })
        }
      })
    },
    ForgetPassword() {
      // 置空 自动获取手机号
      this.ForgetPasswordForm = {}
      if (this.$refs['ForgetPasswordForm'])
        this.$refs['ForgetPasswordForm'].clearValidate()
      if (this.activeName == 'first' && this.ruleForm.user) {
        this.ForgetPasswordForm.Phone = this.ruleForm.user
      }
      if (this.activeName == 'second' && this.ruleForm1.user) {
        this.ForgetPasswordForm.Phone = this.ruleForm1.user
      }
      this.ForgetVisible = true
    },
    handleClick() {
      this.errMsg = ''
      if (this.$refs['ruleForm']) this.$refs['ruleForm'].clearValidate()
      if (this.$refs['ruleForm1']) this.$refs['ruleForm1'].clearValidate()
    },
    // 获取验证码
    sendCode() {
      this.$refs['ForgetPasswordForm'].validate((valid) => {
        if (!valid) {
          return
        }
        clearInterval(this.timer)
        this.disabled = true
        this.timer = setInterval(() => {
          this.oneMinute--
          if (this.oneMinute <= 0) {
            clearInterval(this.timer)
            this.oneMinute = 60
            this.disabled = false
          }
        }, 1000)
        // 客户
        if (this.activeName == 'first') {
          this.$http
            .post(
              '/BO/CustomerLinkman/ResetLinkmanPwd',
              this.ForgetPasswordForm
            )
            .then((res) => {
              if (res.Success) {
                this.$message.success('验证码已经发送至您的手机,请注意查收')
              } else {
                this.$message.error(res.Msg)
              }
            })
        } else {
          this.$http
            .post(
              '/Base_Manage/Base_User/ResetUserPwd',
              this.ForgetPasswordForm
            )
            .then((res) => {
              if (res.Success) {
                this.$message.success('验证码已经发送至您的手机,请注意查收')
              } else {
                this.$message.error(res.Msg)
              }
            })
        }
      })
    },
    submit() {
      this.errMsg = ''
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$http
          .post('/BO/CustomerLinkman/CustomerLinkmanLogin', {
            SignInPhone: this.ruleForm.user,
            SignInSmsCode: this.ruleForm.password,
          })
          .then((res) => {
            this.loading = false
            if (res.Success) {
              this.$router.replace('/home')
              sessionStorage.setItem('userInfo', JSON.stringify(res.Data))
            } else {
              this.errMsg = res.Msg
            }
          })
      })
    },
    CustomerSubmit() {
      this.errMsg = ''
      this.$refs['ruleForm1'].validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$http
          .post('/BO/CustomerLinkman/BserUserLogin', {
            SignInPhone: this.ruleForm1.user,
            SignInSmsCode: this.ruleForm1.password,
          })
          .then((res) => {
            this.loading = false
            if (res.Success) {
              let str = this.base.encode(this.ruleForm1.user) //加密
              window.open(
                'https://psqadmin.yoojet.com/#/Login/LoginPage?c=' + str,
                '_self'
              )
            } else {
              this.errMsg = res.Msg
            }
          })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-tabs__item {
  width: 235px;
  text-align: center;
  border-radius: 10px;
  font-size: 18px;
  line-height: 65px;
  height: 70px;
  font-weight: bold;
}

/deep/.el-tabs__item:hover {
  color: #d50700;
}

/deep/.el-tabs__item.is-active {
  color: #d50700;
}

/deep/.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #d50700;
  z-index: 1;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
  list-style: none;
}

.login {
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  max-height: 1080px;
  background-image: url('../assets/bg1.png');
  background-repeat: no-repeat;
  background-size: cover;

  .formLabel {
    display: flex;
  }

  .formInput {
    margin: 7px 0;

    /deep/.el-input__inner {
      border-radius: 40px;
      // border-color: #888;
    }
  }

  .loginForm {
    width: 400px;
    height: 600px;
    margin-top: 7.2%;
    margin-left: 34.5%;

    .buttonBlock {
      position: relative;
      margin-top: 40px;
      text-align: center;

      .errMsg {
        position: absolute;
        top: -20px;
        left: 80px;
        color: #f56c6c;
        font-size: 12px;
      }
    }

    .Abtn a {
      color: #d50700;
      float: right;
    }

    .formBtn {
      font-weight: bold;
      font-size: 18px;
      width: 60%;
      display: inline-block;
      justify-content: center;
      margin: 0 auto;
      color: #fff;
      background-color: #d50700;
      border-color: #d50700;
    }

    .formBtn:hover {
      color: #fff;
      background-color: #d1110bf3;
      border-color: #d1110bf3;
    }
  }
}

.box {
  padding: 10px;
  background-color: white;
  box-shadow: 0px 5px 40px#d5070060;
  margin-top: 50px;
  width: 470px;
  height: 390px;
  border-radius: 20px;
}

#option {
  border-radius: 20px;
  margin-bottom: 40px;
  width: 92%;
  height: 88px;
  line-height: 40px;
  border-radius: 20px;
  margin-bottom: 40px;
}</style>
